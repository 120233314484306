import React from 'react'
import { ListGroup, ListGroupItem, Row, Col, Container, Button, Input } from 'reactstrap'
import Label from 'reactstrap/lib/Label';
import firebase from 'firebase/app'
import { Range } from '../utils'
import { NumberOfOptions, AdminUid } from '../config'
import Hint from './Hint'



function Option(props) {
    return (
        <Row className="my-2">
            <Col xs="2" className="text-right">
                <Label style={{fontSize: '2em'}}>{props.children}</Label>
            </Col>
            <Col xs="10">
                <Input
                    style={{fontSize: '2em'}}
                    onChange={(e) => props.onChange(e.target.value)}
                    value={props.value}/>
            </Col>
        </Row>
    )
}

class AdminPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            uid: null,
            options: {1: '', 2: '', 3: '', 4: '', 5: '', 6: ''},
            status: 'Initial',
            db: null,
            quizName: null,
        }
    }

    // vCUd7dIHUFUK9FdZFwcWuL2gnZU2

    listenTest = () => {
        const db = firebase.database()
        this.setState({db})

        var testRef = db.ref("test")
        testRef.on("value", (snapshot) => {
            const val = snapshot.val()
            let answered = Array.from(Range(1, NumberOfOptions+1)).map(() => 0)
            if (val !== null) {
                for (let value of Object.values(val)) {
                    answered[value.selectedOption]++
                }
            }
            this.setState({answered})
        })

        var optionsRef = db.ref('options')
        optionsRef.on("value", (snapshot) => {
            const options = snapshot.val()
            this.setState({options})
        })
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({uid: user.uid})
                this.listenTest()
            } else {
                var provider = new firebase.auth.GoogleAuthProvider();
                firebase.auth().signInWithPopup(provider)
            }
          });
    }

    onOptionChange = (key, text) => {
        const options = this.state.options
        options[parseInt(key)] = text
        this.setState({options})
    }

    onQuizNameChange = (text) => {
        this.setState({quizName: text})
    }

    onPublishButtonClicked = () => {
        const db = firebase.database()
        db.ref('test').remove()
        var ref = db.ref('options')
        ref.set({...this.state.options})
        this.setState({status: 'Published'})
    }

    onShowResultButtonClicked = () => {
        this.setState({status: 'ShowResults'})
    }

    onClickNext = () => {
        this.setState({status: 'Initial'})
    }
    
    onSaveButtonClicked = () => {
        const ref = this.state.db.ref('quiz/' + this.state.quizName)
        ref.set({options: this.state.options})
    }

    onLoadButtonClicked = () => {
        const ref = this.state.db.ref('quiz/' + this.state.quizName + '/options')
        ref.on('value', (snapshot) => {
            const options = snapshot.val()
            if (options != null) {
                this.setState({options})
            } else {
                alert("Not found")
            }
        })
    }

    render() {
        if (this.state.uid === null || this.state.uid !== AdminUid) {
            firebase.auth().signOut()
            return '';
        }

        if (this.state.status === 'Published') {
            return (
                <Container>
                    <Hint
                        admin
                        answered={this.state.answered.reduce((e, a) => e+a, 0)}
                        options={this.state.options} />
                    <Button
                        className="mt-2"
                        onClick={this.onShowResultButtonClicked}>看結果</Button>
                </Container>
            )
        } 

        if(this.state.status === 'ShowResults') {
            return (
                <Container>
                    <Results
                        answered={this.state.answered}
                        onClick={this.onClickNext}
                        options={this.state.options}
                        values={this.state.values} />
                </Container>
            )
        }

        return (
            <Container>
                <Options
                    {...this.state}
                    onNameChange={this.onQuizNameChange}
                    onOptionChange={this.onOptionChange}
                    onSaveButtonClicked={this.onSaveButtonClicked}
                    onLoadButtonClicked={this.onLoadButtonClicked}
                    onPublishButtonClicked={this.onPublishButtonClicked}/>
            </Container>
        )
    }
}

function Results(props) {
    return (
        <div>
            <ListGroup className="mt-2">
                {Array.from(Range(1, NumberOfOptions)).map((e) => (
                    <ListGroupItem 
                        style={{fontSize: '1.5em'}}
                        className="my-2"
                        key={'result-' + e}>
                        <Row>
                            <Col xs="9" lg="6" className="border-right">
                                {props.options[e]}
                            </Col>
                            <Col xs="3" lg="6">
                                {props.answered[e]} 人
                            </Col>
                        </Row>
                    </ListGroupItem>
                ))}
            </ListGroup>
            <Button className="mt-2" onClick={props.onClick}>下一個問題</Button>
        </div>
    )
}

function Options(props) {
    return (
        <div>
            <Row className="py-2 mt-2">
                <Col xs={{size: 4, offset: 2}} lg={{size: 2, offset: 1}}>
                    <Input onChange={(e) => props.onNameChange(e.target.value)}/>
                </Col>
                <Col xs="6" lg="3" className="text-right">
                    <Button onClick={props.onSaveButtonClicked} className="mx-2">儲存</Button>
                    <Button onClick={props.onLoadButtonClicked} className="mx-2">載入</Button>
                </Col>
            </Row> 
            <Row>
                <Col xs="12" lg="6">
                    {Array.from(Range(1, NumberOfOptions)).map((e) => (
                        <Option
                            key={'option-' + e}
                            onChange={(text) => props.onOptionChange(e, text)}
                            value={props.options[e]}>({e})</Option>
                    ))}
                </Col>
            </Row>
            <Row>
                <Col xs="12" lg="6" className="text-right">
                    <Button onClick={props.onPublishButtonClicked} color="primary">發布</Button>
                </Col>
            </Row>
        </div>
    )
}

export default AdminPage
