import React from 'react'
import { Row, Col } from 'reactstrap'

function Title(props) {
  return (
      <Row>
        <Col className="my-3"><h1>{props.children}</h1></Col>
      </Row>
  )
}

export default Title