import React from 'react'
import { Container } from 'reactstrap'
import './App.css'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import AdminPage from './components/AdminPage'
import Hint from './components/Hint'
import Title from './components/Title'
import { NumberOfOptions } from './config';

var firebaseConfig = {
  apiKey: "AIzaSyArflkt1gImR_vWsI3-YD0OblNlWUFeBwA",
  authDomain: "polling-83ce7.firebaseapp.com",
  databaseURL: "https://polling-83ce7.firebaseio.com",
  projectId: "polling-83ce7",
  storageBucket: "polling-83ce7.appspot.com",
  messagingSenderId: "473713352519",
  appId: "1:473713352519:web:e0413b9bd3303468"
};

firebase.initializeApp(firebaseConfig);

function App(props) {
  return (
    <Router>
      <Route path="/" exact component={Page} />
      <Route path="/admin" component={AdminPage} />
    </Router>
  )
}

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {selectedOption: 0, uid: null, options: ['', '', '', '', '']}
  }

  loadOptions = (user) => {
    var db = firebase.database()
    var ref = db.ref('test/' + user.uid)
    ref.on("value", (snapshot) => {
      const val = snapshot.val()
      var selectedOption = 0
      if (val !== null) {
        selectedOption = val.selectedOption
      }
      this.setState({selectedOption})
    })

    var optionsRef = db.ref('options')
    optionsRef.on("value", (snapshot) => {
      const options = snapshot.val()
      console.log(options)
      this.setState({uid: user.uid, options})
    })

    var testRef = db.ref("test")
    testRef.on("value", (snapshot) => {
      if (snapshot.val() === null) {
        this.setState({answered: 0})
      } else {
        this.setState({answered: Object.keys(snapshot.val()).length})
      }
    })
  }

  componentDidMount() {
    document.addEventListener("keydown", (e) => {
      if (this.state.uid === null || this.state.selectedOption !== 0) return

      if (e.keyCode >= 49 && e.keyCode <= 49 + NumberOfOptions - 1) {
        const selectedOption = e.keyCode - 49 + 1
        this.setState({selectedOption})
        var db = firebase.database()
        var ref = db.ref('test/' + this.state.uid + '/')
        ref.set({selectedOption})
      }
    }, false);

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.loadOptions(user)
      } else {
        firebase.auth().signInAnonymously().catch(function(error) {
          alert(error)
        })
      }
    });
  }

  render() {
    if (this.state.uid === null || this.state.options === null) 
      return <Loading />

    return (
      <Container className="px-0">
        <Hint {...this.state} />
      </Container>
    )
  }
}

function Loading(props) {
  return (
    <Container>
      <Title>載入題目中...</Title>
    </Container>
  )
}



export default App;
